import _ from 'lodash';
import { NextSeo } from 'next-seo';
import React from 'react';
import Router, { withRouter } from 'next/router';

import { SiteContext } from '@/globals/contexts';
import { getApiUrl } from '@/utils/config';

import RawComponent from '@/PUFComponents/RawComponent';
import ComponentBase from '@/PUFComponents/ComponentBase';

class LoginPage extends ComponentBase {
  static async getInitialProps(ctx) {
    return {};
  }

  didMount() {
    this.setState({ previousUrl: window.previousUrl });
  }

  onLoginSuccess = () => {
    Router.back(); // TODO mieux
  };

  render() {
    const { siteContext } = this.props;
    const bgImage = _.get(this.props, 'siteConfig.options.bgImage');
    const {
      siteConfig: {
        styles: { template },
      },
    } = siteContext;

    return (
      <div
        className="Page Login"
        style={{
          backgroundImage: bgImage && 'url("' + getApiUrl() + bgImage + '")',
        }}
      >
        <NextSeo title="Login" />

        <div className="Page-body">
          <div
            className={
              template === 'template2' ? 'content-container' : 'siteWrapper'
            }
          >
            <RawComponent
              id="Login"
              componentProps={{
                onSuccess: this.onLoginSuccess,
                from: this.state.previousUrl,
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter((props) => (
  <SiteContext.Consumer>
    {(siteContext) => (
      <LoginPage {...props} siteConfig={siteContext.siteConfig} />
    )}
  </SiteContext.Consumer>
));
